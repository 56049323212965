.content{
  width:100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
}

.steps{
  position: absolute;
  top: 100px;

}

.content_box{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
}
.loading{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}