@import '~antd/dist/antd.css';

.App {
    /* width: 100vw;
    height: 100vh;
    border: 1px solid red; */
}

#components-layout-demo-responsive .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
    /* background: #fff; */
}

.site-layout-background {
    background: #fff;
}